import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { MenuItem } from "@models/menu.models";
import { Subscription } from "rxjs";
import { filter, tap } from "rxjs/operators";

@Component({
  selector: "ngx-siqueirasoft-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  private subscribe: Subscription;
  public menuItems: Array<MenuItem> = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.subscribe = this.router.events
      .pipe(
        // tap(console.log),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(
        () =>
          (this.menuItems = this.createBreadcrumbs(this.activatedRoute.root))
      );
  }

  ngOnInit(): void {}
  ngOnDestroy() {
    if (this.subscribe) {
      this.subscribe.unsubscribe();
    }
  }
  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = "#",
    breadcrumbs: MenuItem[] = []
  ): MenuItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url
        .map((segment) => segment.path)
        .join("/");
      if (routeURL !== "") {
        url += `/${routeURL}`;
      }

      const { breadcrumb, path } = child.snapshot.data;
      if (breadcrumb && routeURL === path) {
        breadcrumbs.push({ title: breadcrumb, url });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  navigate(url) {
    // this.router.navigate([url])
  }
}
