import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { BaseService } from "./base.service";

export abstract class CrudService extends BaseService {
  constructor(protected httpClient: HttpClient) {
    super();
  }

  /**
   * Método que deve retornar qual o endpoint da api
   */
  abstract get apiEndpoint(): string;

  /**
   * Método para listar os dados
   *
   * @param params
   * @returns
   */
  list(params: HttpParams) {
    return this.resolveRequest(
      this.httpClient.get<any>(`${this.apiUrl}/api/${this.apiEndpoint}`, {
        headers: this.httpHeaders,
        params: params,
      })
    ).pipe(map(this.formatList));
  }

  /**
   * Formata os dados da lista
   *
   * @param data
   * @returns
   */
  formatList(data) {
    return data;
  }

  /**
   * Método para recuperar uma entidade
   *
   * @param id
   * @returns
   */
  getById(id: any) {
    return this.resolveRequest(
      this.httpClient.get<any>(`${this.apiUrl}/api/${this.apiEndpoint}/` + id, {
        headers: this.httpHeaders,
      })
    );
  }

  /**
   * Método para recuperar as alterações de uma entidade
   *
   * @param id
   * @returns
   */
  revision(id: any) {
    return this.resolveRequest(
      this.httpClient.get<any>(
        `${this.apiUrl}/api/${this.apiEndpoint}/${id}/revisions`,
        {
          headers: this.httpHeaders,
        }
      )
    );
  }

  /**
   * Método para criar uma entidade
   *
   * @param body
   * @returns
   */
  create(body) {
    return this.resolveRequest(
      this.httpClient.post<any>(
        `${this.apiUrl}/api/${this.apiEndpoint}`,
        JSON.stringify(body),
        { headers: this.httpHeaders }
      )
    );
  }

  /**
   * Método para atualizar uma entidade
   *
   * @param body
   * @param id
   * @returns
   */
  update(body, id) {
    return this.resolveRequest(
      this.httpClient.put<any>(
        `${this.apiUrl}/api/${this.apiEndpoint}/` + id,
        JSON.stringify(body),
        { headers: this.httpHeaders }
      )
    );
  }

  /**
   * Método para remover uma entidade
   *
   * @param id
   * @returns
   */
  delete(id) {
    return this.resolveRequest(
      this.httpClient.delete<any>(
        `${this.apiUrl}/api/${this.apiEndpoint}/` + id,
        {
          headers: this.httpHeaders,
        }
      )
    );
  }
}
