<div fxLayout="row wrap" style="padding-top: 5px; padding-bottom: 5px;">
  <span style="font-size: small;"><b>Início</b></span>
  <span *ngFor="let item of menuItems" style="font-size: small;">
    &nbsp;<b>></b>&nbsp;<b>{{item.title}}</b>
  </span>

  <!-- <button nbButton ghost size="tiny" (click)="navigate('/')">Início</button>
  <span *ngFor="let item of menuItems">
    >
    <button nbButton ghost size="tiny" (click)="navigate(item.url)">
      {{ item.title }}
    </button>
  </span> -->
</div>
