import { Router } from "@angular/router";
import { map, catchError, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { environment } from "@environments/environment";
import { User } from "@models/user.models";
import { SecureLocalStorageService } from "./localstorage.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  readonly authenticationUrl = environment.apiUrl;

  public static readonly ACCESS_TOKEN = "access_token";
  public static readonly USER = "user";
  public static loggedUser: User;

  constructor(protected http: HttpClient, protected router: Router) {}

  static storeToken(access_token: string) {
    SecureLocalStorageService.setItem(
      AuthenticationService.ACCESS_TOKEN,
      access_token
    );
  }

  static storeUser(user) {
    AuthenticationService.loggedUser = new User(user);
    SecureLocalStorageService.setItem(
      AuthenticationService.USER,
      JSON.stringify(user)
    );
  }

  static getToken() {
    return SecureLocalStorageService.getItem(
      AuthenticationService.ACCESS_TOKEN
    );
  }

  static getUser(): User {
    if (AuthenticationService.loggedUser) {
      return AuthenticationService.loggedUser;
    }

    if (SecureLocalStorageService.getItem(AuthenticationService.USER)) {
      const user = JSON.parse(
        SecureLocalStorageService.getItem(AuthenticationService.USER)
      );
      AuthenticationService.loggedUser = new User(user);

      return AuthenticationService.loggedUser;
    } else {
      return null;
    }
  }

  login(email: string, password: string) {
    return this.http
      .post<any>(`${this.authenticationUrl}/api/auth/login`, {
        email: email,
        password: password,
      })
      .pipe(
        map((result: any) => {
          AuthenticationService.storeToken(result.access_token);
          return result;
        }),
        catchError(this.error)
      );
  }

  resetPassword(email: string) {
    return this.http
      .post<any>(`${this.authenticationUrl}/api/auth/request-pass`, {
        email: email
      });
  }

  refreshToken() {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthenticationService.getToken()}`,
    });

    return this.http
      .post<any>(`${this.authenticationUrl}/api/auth/refresh`, null, {
        headers: httpHeaders,
      })
      .pipe(
        tap((result: any) => {
          AuthenticationService.storeToken(result.access_token);
          return result;
        }),
        catchError(this.error)
      );
  }

  userData() {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthenticationService.getToken()}`,
    });

    return this.http
      .get<any>(`${this.authenticationUrl}/api/auth/me`, {
        headers: httpHeaders,
      })
      .pipe(
        tap((result: any) => {
          return result;
        }),
        catchError(this.error)
      )
      .toPromise();
  }

  private error(e): Observable<ErrorEvent> {
    return throwError(e);
  }

  logout() {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${AuthenticationService.getToken()}`,
    });

    return this.http
      .post<any>(`${this.authenticationUrl}/api/auth/logout`, null, {
        headers: httpHeaders,
      })
      .pipe(
        map((result: any) => {}),
        catchError(this.error)
      );
  }
}
