import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateChild,
} from "@angular/router";
import { AuthenticationService } from "@services/auth.service";
import { SecureLocalStorageService } from "@services/localstorage.service";
import { Observable } from "rxjs";

@Injectable()
export class PermissionGuard implements CanActivate, CanActivateChild {
  constructor(private authenticationService: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.updatePermission();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.updatePermission();
  }

  private updatePermission() {
    const hasExecuted = SecureLocalStorageService.getItem("updatePermission");

    if (hasExecuted) {
      return true;
    }

    return new Observable<boolean>((observer) => {
      this.authenticationService
        .userData()
        .then((user: any) => {
          AuthenticationService.storeUser(user);
          SecureLocalStorageService.setItem("updatePermission", true);
          observer.next(true);
          observer.complete();
        })
        .catch((err) => {
          SecureLocalStorageService.setItem("updatePermission", true);
          observer.next(true);
          observer.complete();
        });
    });
  }
}
