export class UserGroup {
  group_id: number;
  group_name: string;
  company_id: number;
  company_name: string;
}

export class GenericItem {
  id: number;
  name: string;
}

export class User {
  id: string;
  name: string;
  email: string;
  telegram_user: string;
  super_admin: number;
  company_id: string;
  permissions: Array<string> = [];
  group: Array<UserGroup> = [];
  groups: Array<GenericItem> = [];
  companys: Array<GenericItem> = [];
  reseted?: number;

  /**
   * Inicializa um usuário
   *
   * @param Params
   */
  constructor({
    id,
    name,
    email,
    telegram_user,
    super_admin,
    company_id,
    permission,
    group,
    reseted,
  }) {
    // Salva os dados inforamdos
    this.id = id;
    this.name = name;
    this.email = email;
    this.telegram_user = telegram_user;
    this.super_admin = super_admin;
    this.company_id = company_id;
    this.permissions = permission;
    this.group = group;
    this.reseted = reseted;

    // Monta a lista de grupos que o usuário possui acesso
    this.groups = this.group
      .map((group) => {
        return {
          id: group.group_id,
          name: group.group_name,
        };
      })
      .sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    // Monta a lista de empresas que o usuário possui acesso
    this.companys = this.group.map((group) => {
      return {
        id: group.company_id,
        name: group.company_name,
      };
    });

    // Remove duplicados
    this.companys = Array.from(new Set(this.companys.map((a) => a.id))).map(
      (id) => {
        return this.companys.find((a) => a.id === id);
      }
    );

    // Ordena pelo nome
    this.companys = this.companys.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  /**
   * Verifica se o usuário possui a permissão informada
   * @param permission
   */
  can(permission) {
    // Se for super-admin devemos liberar todos os acessos
    if (this.super_admin === 1) {
      return true;
    }

    if (permission == "dashboard") {
      return true;
    }

    // Verifica se possui a permissão solicitada
    return this.permissions.includes(permission);
  }
}
