import { Router } from "@angular/router";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { environment } from "@environments/environment";
import { Notification } from "@models/notification.models";

@Injectable({ providedIn: "root" })
export class UserService {
  public notifications: Array<Notification> = [];
  public notifications_unread: Array<Notification> = [];

  readonly apiUrl = environment.apiUrl;

  private httpHeaders = new HttpHeaders({ "Content-Type": "application/json" });

  constructor(protected httpClient: HttpClient, protected router: Router) {}

  getUserById(id: any) {
    return this.resolveRequest(
      this.httpClient.get<any>(`${this.apiUrl}/api/user/` + id, {
        headers: this.httpHeaders,
      })
    );
  }

  fetchAvailablePermissions() {
    return this.resolveRequest(
      this.httpClient.get<any>(`${this.apiUrl}/api/permissions/`, {
        headers: this.httpHeaders,
      })
    );
  }

  fetchAvailableRoles() {
    return this.resolveRequest(
      this.httpClient.get<any>(`${this.apiUrl}/api/role/`, {
        headers: this.httpHeaders,
      })
    );
  }

  getUsers() {
    return this.resolveRequest(
      this.httpClient.get<any>(`${this.apiUrl}/api/user`, {
        headers: this.httpHeaders,
      })
    );
  }

  resolveRequest = (request: Observable<any>) =>
    request.pipe(catchError(this.error))

  private error(e): Observable<ErrorEvent> {
    return throwError(e);
  }
}
