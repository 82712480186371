import { AuthenticationService } from "@services/auth.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CrudService } from "./crud.service";

@Injectable({ providedIn: "root" })
export class VisualizarNotificacoesService extends CrudService {
  constructor(
    protected httpClient: HttpClient,
    private authenticationService: AuthenticationService
  ) {
    super(httpClient);
  }

  // Endpoint do perfil de acesso
  get apiEndpoint() {
    return "notifications/list";
  }

  // Visualização das notificações não lidas
  getUnread() {
    return this.resolveRequest(
      this.httpClient.get<any>(`${this.apiUrl}/api/notifications/unread`, {
        headers: this.httpHeaders
      })
    );
  }

  // Visualização das notificações não lidas
  maskAsRead() {
    return this.resolveRequest(
      this.httpClient.post<any>(`${this.apiUrl}/api/notifications/unread`, {
        headers: this.httpHeaders
      })
    );
  }
}
