import { Router } from "@angular/router";
import { AuthenticationService } from "./../../../services/auth.service";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
  NB_WINDOW,
} from "@nebular/theme";

import { filter, map, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { environment } from "@environments/environment";
import { UserService } from "@services/user.service";
import { Notification } from "@models/notification.models";
import { SecureLocalStorageService } from "@services/localstorage.service";
import { VisualizarNotificacoesService } from "@services/visualizar_notificacoes.service";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  public systemName = environment.systemName;
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  static avaiableThemes = [
    {
      value: "default",
      name: "Padrão",
    },
    {
      value: "dark",
      name: "Escuro",
    },
    {
      value: "cosmic",
      name: "Cosmic",
    },
    {
      value: "corporate",
      name: "Corporate",
    },
  ];

  private _currentTheme = "default";

  get currentTheme() {
    return this._currentTheme;
  }

  set currentTheme(newTheme) {
    this._currentTheme = newTheme;

    // Salva o tema para casos de refresh
    SecureLocalStorageService.setItem("theme", newTheme);
  }

  get themes() {
    return HeaderComponent.avaiableThemes;
  }

  userMenu = this.getMenuItems();

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    public userService: UserService,
    @Inject(NB_WINDOW) private window,
    private _notifications: VisualizarNotificacoesService
  ) {}

  getMenuItems(): Array<NbMenuItem> {
    return [
      // {
      //   title: "Tema",
      //   children: [
      //     {
      //       title: "Ligth",
      //       data: "default",
      //     },
      //     {
      //       title: "Dark",
      //       data: "dark",
      //     },
      //     {
      //       title: "Cosmic",
      //       data: "cosmic",
      //     },
      //     {
      //       title: "Corporate",
      //       data: "corporate",
      //     },
      //   ],
      // },
      { title: "Sair", data: "logout" },
    ];
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    // this.userStore.onUserStateChange()
    //   .pipe(
    //     takeUntil(this.destroy$),
    //   )
    //   .subscribe((user: any) => {
    //     this.user = user;
    //     this.userMenu = this.getMenuItems();
    //   });

    this.userMenu = this.getMenuItems();

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));

    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => {
          return tag === "headerUserMenu";
        })
      )
      .subscribe((item) => {
        // console.log(item);
      });

    this.loadNotifications();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(false, "menu-sidebar");

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  logout() {
    this.authenticationService.logout().subscribe(() => {
      // Limpa o storage
      SecureLocalStorageService.clear();
      AuthenticationService.loggedUser = null;

      // Limpa as notificações
      this.userService.notifications_unread.length = 0;
      this.userService.notifications.length = 0;
      this.router.navigate(["auth/login"]);
    });
  }

  perfil() {
    this.router.navigate(["/perfil"]);
  }

  async loadNotifications() {
    const notificacoes = await this._notifications.getUnread().toPromise();
    notificacoes.forEach((notificacao) => {
      let type;

      switch (notificacao.data?.type) {
        case "ERROR":
          type = "error";
          break;
        case "ALERT":
          type = "warning";
          break;
        default:
          type = "error";
      }
      // Remove o botão para visualizar
      if (notificacao?.data?.message) {
        notificacao.data.message = notificacao.data.message.split("<a")[0];
      }

      this.userService.notifications_unread.unshift({
        type: "warning",
        text: null,
        title: notificacao?.data?.message || "Não disponível",
        date: new Date(notificacao.created_at),
      });
    });
  }

  async maskAsRead() {
    this.userService.notifications_unread
      .slice()
      .reverse()
      .forEach((unread) => {
        this.userService.notifications.unshift(unread);
      });

    this.userService.notifications_unread = [];

    await this._notifications.maskAsRead().toPromise();
  }

  getIconStatus(notificacao) {
    return notificacao.type;
  }

  getNotificationIcon(type) {
    switch (type) {
      case "success":
        return "checkmark-circle-outline";
      case "danger":
        return "close-circle-outline";
      case "info":
        return "alert-circle-outline";
      case "warning":
        return "alert-triangle-outline";
    }
  }

  getNotificationTitle(notificacao: Notification) {
    let title;
    switch (notificacao.type) {
      case "success":
        title = "Sucesso";
        break;
      case "danger":
        title = "Erro";
        break;
      case "info":
        title = "Informação";
        break;
      case "warning":
        title = "Aviso";
        break;
    }

    return `${title}: ${notificacao.text || notificacao.title}`;
  }

  clearNotifications() {
    this.userService.notifications = [];
    this.userService.notifications_unread = [];
  }
}
