import * as SecureLS from "secure-ls";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class SecureLocalStorageService {
  constructor() {}

  static get ls() {
    return new SecureLS({
      encodingType: "aes",
      encryptionSecret: "rnJw7LfMjjV6tDXj",
    });
  }

  static setItem(key: string, value: any, expired: number = 0) {
    SecureLocalStorageService.ls.set(key, value);
  }

  static remove(key: string) {
    SecureLocalStorageService.ls.remove(key);
  }

  static getItem(key: string) {
    return SecureLocalStorageService.ls.get(key);
  }

  static clear() {
    SecureLocalStorageService.ls.removeAll();
  }
}
