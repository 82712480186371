<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()" style="border: none;" fxHide.xs>
      <!-- <span>{{ systemName }}</span> -->
    </a>
  </div>
</div>

<div class="header-container cursor-pointer">
  <button
    nbButton
    ghost
    title="Notificações"
    [nbPopover]="notificationsTemplate"
    nbPopoverPlacement="bottom"
    (click)="maskAsRead()"
  >
    <mat-icon [matBadge]="userService.notifications_unread.length > 9 ? '+9' : userService.notifications_unread.length" id="notificationBadge"
      >notifications</mat-icon
    >
  </button>
  <nb-select
    [selected]="currentTheme"
    (selectedChange)="changeTheme($event)"
    status="primary"
    size="small"
  >
    <nb-option *ngFor="let theme of themes" [value]="theme.value">
      {{ theme.name }}</nb-option
    >
  </nb-select>
  <!-- <button
    nbButton
    ghost
    title="Usuário"
    [nbContextMenu]="getMenuItems()"
    nbContextMenuTag="headerUserMenu"
    nbContextMenuTrigger="hover"
  >
    <nb-icon icon="person"></nb-icon>
  </button> -->
  <button nbButton ghost title="Perfil" (click)="perfil()">
    <nb-icon icon="person"></nb-icon>
  </button>
  <button nbButton ghost title="Sair" (click)="logout()">
    <nb-icon icon="power"></nb-icon>
  </button>
</div>

<ng-template #notificationsTemplate>
  <nb-list style="min-width: 25vw">
    <nb-list-item *ngIf="!userService.notifications.length">
      Sem notificações
    </nb-list-item>
    <nb-list-item
      *ngFor="let notificacao of userService.notifications"
      style="padding: 0; cursor: pointer"
      [title]="getNotificationTitle(notificacao)"
    >
      <nb-card style="padding: 0; width: 100%; margin: 0; border: 0">
        <nb-card-header
          style="
            border: 0;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
          "
        >
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            style="width: 100%"
          >
            <div fxFlex="75">
              <nb-icon
                [icon]="getNotificationIcon(notificacao.type)"
                style="padding-right: 5px; height: 1.25em"
                [status]="getIconStatus(notificacao)"
              ></nb-icon
              ><div [innerHTML]="notificacao.title"></div>
            </div>
            <div
              fxFlex="25"
              style="font-size: 10px; display: contents; float: right"
            >
              {{ notificacao.date | dateAgo }}
            </div>
          </div>
        </nb-card-header>
      </nb-card>
    </nb-list-item>

    <nb-list-item
      style="padding: 0; cursor: pointer"
      (click)="clearNotifications()"
      *ngIf="userService.notifications.length"
    >
      <nb-card style="padding: 0; width: 100%; margin: 0; border: 0">
        <nb-card-header
          style="
            border: 0;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            display: flex;
            justify-content: center;
            font-size: 12px;
            padding: 0;
          "
        >
          Limpar notificações
        </nb-card-header>
      </nb-card>
    </nb-list-item>
  </nb-list>
</ng-template>
