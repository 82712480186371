import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { BreadcrumbComponent } from "./breadcrumb.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NbButtonModule, NbIconModule, NbTagModule } from "@nebular/theme";

@NgModule({
  declarations: [BreadcrumbComponent],
  exports: [BreadcrumbComponent],
  imports: [CommonModule, FlexLayoutModule, NbButtonModule, NbIconModule, NbTagModule ],
})
export class BreadcrumbModule {}
